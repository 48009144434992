<template>
    <div class="page">
        <header>
            <van-nav-bar
                    title="我的订单"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>
        <nav class="navBar">
            <ul>
                <li v-for="(item, index) in navList" :key="index" :class="index == curIndex?'active':''"
                    @click="handleClick(index, item)">{{ item.title }}
                </li>
            </ul>
        </nav>
        <section>
            <div class="box order" v-for="(userOrder, index) in userOrders" :key="index">
                <div class="orderTitle">
                    <div class="orderGroup">
                        <label>订单号</label>
                        <span>{{ userOrder.orderCode  }}</span>
                    </div>
                    <div class="status" :class="userOrder.statusColor">{{ userOrder.orderStatusName }}</div>
                </div>
                <div class="good" @click="handleOrderInfo(userOrder.orderCode,userOrder.type)">
                    <img :src="userOrder.orderImagePath" alt="">
                    <div class="goodinfo">
                        <div class="goodname">
                            <label>
                                <span style="display: none">自营</span>
                                <span style="font-size: 1.12rem;">{{userOrder.orderName}}</span>
                            </label>
                            <span class="goodNum" v-show="userOrder.isShangpin">X1</span>
                        </div>
                        <div class="shipping" v-show="userOrder.isShangpin">
                            <span>包邮</span>
                        </div>
                        <div class="price" style="margin-top: 0.6rem">
                            <span>¥</span>
                            <span>{{userOrder.orderPrice}}</span>
                        </div>
                        <div class="text_16" style="margin-top: 1rem">
                            <span>{{userOrder.createTime}}</span>
                        </div>
                        <div class="curStores" v-show="userOrder.isShangpin">
                            <span>{{userOrder.shopName}}</span>
                        </div>
                    </div>
                </div>
                <div class="cancelOrder" v-if="userOrder.type != 1 && userOrder.orderStatusName == '待付款'">
                    <van-button @click="openPayment(userOrder.orderCode)" plain round type="info" class="cancelBtn">
                        立即支付
                    </van-button>
                    &nbsp;<van-button @click="cancelOrderInfo(userOrder.orderCode,1)" plain round type="info" class="cancelBtn">
                    取消订单
                    </van-button>
                </div>
                <div class="cancelOrder" v-if="userOrder.orderStatusName == '已取消' || userOrder.orderStatusName == '已过期'">
                    <van-button @click="cancelOrderInfo(userOrder.orderCode,0)" plain round type="info" class="cancelBtn">
                        删除订单
                    </van-button>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import {queryUserByAccount} from "@/api/user";
    import {getOpenId} from "@/api/order";
    import {queryOrderByMemberAccount, cancelOrderInfo, jsapiPay} from "@/api/order";
    import axios from 'axios';

    export default {
        inject:['reload'], // 使用 inject 注入 reload 变量
        data() {
            return {
                isWeChatOpened: false,
                baseURL: axios.defaults.baseURL,
                memberAccount: '',
                memberId: '',
                curIndex: 0,
                userOrders: [],
                navList: [
                    {title: '全部'},
                    {title: '待发货'},
                    {title: '待收货'},
                    {title: '已收货'},
                ],
                cancelOrderInfoParam: {
                    orderCode: '',
                    orderStatus: '',
                    dr: ''
                },
                payParam: {
                    userId: '',
                    orderCode: ''
                },
                openIdParams: {},
            };
        },
        mounted() {
            const user = localStorage.getItem('userInfoDs')
            if (user){
                this.memberAccount = JSON.parse(user).account
                this.memberId = JSON.parse(user).memberId
                this.queryOrderByMemberAccount(this.memberAccount,this.memberId)
            } else {
                this.$router.push('/login')
            }
        },
        methods: {
            isWechatOpen(){
                const userAgent = navigator.userAgent; // 获取User Agent字符串
                if (/micromessenger/i.test(userAgent)) {
                    return true; // 如果User Agent中包含"MicroMessenger"关键词，返回true
                } else {
                    return false; // 其他情况返回false
                }
            },
            queryOrderByMemberAccount(memberAccount,memberId) {
                queryOrderByMemberAccount(memberAccount,memberId).then(response => {
                    if (response.code == 200) {
                        response.data.forEach((item) => {
                            if (item.orderImagePath) {
                                item.orderImagePath = this.baseURL + item.orderImagePath;
                            } else {
                                item.orderImagePath = require('../wode/assets/img/53b0ccbf5db8cfc1a66e5783fe003148.png')
                            }
                            if (item.type == 3) {
                                item.orderName = '购买会员订单'
                            }
                            if (item.type == 2) {
                                item.isShangpin = true
                            } else {
                                item.isShangpin = false
                            }
                            if (item.orderStatusName == '已完成') {
                                item.statusColor = 'arrive'
                            } else if (item.orderStatusName == '待付款') {
                                item.statusColor = 'pending'
                            } else {
                                item.statusColor = 'status'
                            }
                            this.userOrders.push(item)
                        })
                    } else {
                        this.$model({
                            show: true,
                            title: "提示",
                            content: response.msg,
                            confirmButton: false,
                            cancelButton: true
                        });
                    }
                });
            },
            cancelOrderInfo(orderCode, dr) {
                this.cancelOrderInfoParam.orderCode = orderCode
                //订单状态 1 待付款 2 待发货 3 已发货 4 待评价 0已完成 5已取消 6退款中 7退款成功 8退款异常
                this.cancelOrderInfoParam.orderStatus = 5
                this.cancelOrderInfoParam.dr = dr
                let msg = '确认取消吗？'
                if(dr<1){
                    msg = '确认删除吗？'
                }
                this.$model({
                    show: true,
                    title: "提示",
                    content: msg,
                    confirmButton: true,
                    cancelButton: true,
                    confirmText: '确认',
                    cancelText: '取消',
                    cancelCallBack: () => {
                    },
                    confirmCallBack: () => {
                        cancelOrderInfo(this.cancelOrderInfoParam).then(response => {
                            this.$model({
                                show: true,
                                title: "提示",
                                content: response.msg,
                                confirmButton: false,
                                cancelButton: true
                            });
                            this.reload()
                            //this.queryOrderByMemberAccount(this.memberAccount)
                        });
                    }
                });
            },
            openPayment(orderCode) {
                this.isWeChatOpened = this.isWechatOpen();
                if(!this.isWeChatOpened){
                    this.$model({
                        show: true,
                        title: "提示",
                        content: "支付失败，请从微信进入点击支付！",
                        confirmButton: false,
                        cancelButton: true
                    });
                    return false
                }
                queryUserByAccount(this.memberAccount).then(response => {
                    if(response.code!=200){
                        this.$model({
                            show: true,
                            title: "提示",
                            content: "账号不存在",
                            confirmButton: false,
                            cancelButton: true
                        });
                        return false
                    }
                });
                const userOpenId = localStorage.getItem('userOpenIdDs')

                this.payParam.orderCode = orderCode
                this.payParam.memberAccount = this.memberAccount

                if(userOpenId){
                    this.payParam.openId = userOpenId
                    this.jsapiPay()
                }else{
                    let code = this.getUrlCode('code');
                    //alert("code1：" + code)
                    if(!code){
                        this.getWeChatCode();
                    }
                    //alert("获取到得code：" + code)
                    this.openIdParams.code = code;
                    getOpenId(this.openIdParams).then(response => {
                        if (response.code == 200) {
                            this.payParam.openId = response.data.openId
                            localStorage.setItem('userOpenIdDs', response.data.openId)
                            this.jsapiPay()
                        }else{
                            this.getWeChatCode();
                            //alert("获取到得code2：" + code)
                            getOpenId(this.openIdParams).then(response => {
                                if (response.code == 200) {
                                    this.payParam.openId = response.data.openId
                                    localStorage.setItem('userOpenIdDs', response.data.openId)
                                    this.jsapiPay()
                                }else{
                                    this.$model({
                                        show: true,
                                        title: "提示",
                                        content: "授权失败，请退出从微信重新进入！",
                                        confirmButton: false,
                                        cancelButton: true
                                    });
                                }
                            });
                        }
                    });
                }

            },
            jsapiPay(){
                jsapiPay(this.payParam).then(response => {
                    if (response.code == 200) {
                        let data = response.data;
                        let that = this
                        /* eslint-disable */
                        WeixinJSBridge.invoke('getBrandWCPayRequest', {
                            "appId": data.appId,
                            "timeStamp": data.timeStamp,
                            "nonceStr": data.nonceStr,
                            "package": data.packagestr,
                            "signType": "RSA",
                            "paySign": data.paySign
                        },res => {
                            if (res.err_msg == "get_brand_wcpay_request:ok") {
                                alert("支付成功");
                                that.$router.push('/payResults')
                                //rallbackScheduleConfig(this.payParam.memberAccount).then(response => {});
                            }
                            if(res.err_msg == "get_brand_wcpay_request:fail"){
                                alert("支付失败");
                            }
                            if(res.err_msg == "get_brand_wcpay_request:cancel"){
                                //alert("支付取消");
                                this.cancelOrderInfoParam.orderCode = this.payParam.orderCode
                                this.cancelOrderInfoParam.orderStatus = 5
                                this.cancelOrderInfoParam.dr = 1
                                cancelOrderInfo(this.cancelOrderInfoParam).then(response => {
                                    this.$model({
                                        show: true,
                                        title: "提示",
                                        content: response.msg,
                                        confirmButton: false,
                                        cancelButton: true
                                    });
                                    this.reload()
                                    //this.queryOrderByMemberAccount(this.memberAccount)
                                });
                            }

                        });
                        /* eslint-enable */
                    }else{
                        this.$model({
                            show: true,
                            title: "提示",
                            content: response.msg,
                            confirmButton: false,
                            cancelButton: true
                        });
                    }

                });
            },
            // 返回
            onClickLeft() {
                this.$router.push('/wode')
            },
            // nav 点击事件
            handleClick(index, item) {
                this.curIndex = index
                console.log('item-nav', item);
            },
            // 订单详情
            handleOrderInfo(orderCode, type) {
                //1菜品2商品3购买会员订单
                if (type == 2) {
                    this.$router.push({name: 'userOrderInfo', query: {orderCode: orderCode}});
                }
                if (type == 1) {
                    this.$router.push({name: 'orderInfo', query: {orderCode: orderCode}});
                }
            },
            // 获取url中的code方法
            getUrlCode(name) {
                return (
                    decodeURIComponent(
                        (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
                            location.href
                        ) || ["", ""])[1].replace(/\+/g, "%20")
                    ) || null
                );
            },
            getWeChatCode() {
                //授权后重定向的回调链接地址(即需要调跳转的H5页面)
                let urlNow = encodeURIComponent(window.location.href);
                //alert(urlNow)
                // 通过微信官方接口获取code之后，会重新刷新设置的回调地址【redirect_uri】
                let timeStamp = new Date().getTime();
                let url = 'https://open.weixin.qq.com/connect/oauth2/authorize?' +
                    'appid=wx2dcff060cfde0dc4&response_type=code&scope=snsapi_base' +
                    '&redirect_uri=' + urlNow + '&state=' + timeStamp + '&connect_redirect=1#wechat_redirect';
                window.location.href = url
            },
        }
    };
</script>
<style scoped lang="css">
    * {
        padding: 0;
        margin: 0;
    }

    html,
    body {
        font-size: 37.5px;
        height: 100%;
    }

    .page {
        height: 100vh;
        display: flex;
        flex-direction: column;
    }

    header {
        height: 4rem;
        background-color: pink;
    }

    section {
        flex: 1;
        background-color: #f3f3f3;
        padding: 1.12rem 1.28rem;
        overflow: auto;
    }

    .box {
        box-shadow: 0px 2px 10px 0px rgba(218, 218, 218, 1);
        background-color: rgba(255, 255, 255, 1);
        border-radius: 20px;
        padding: 1rem;
        background-color: #fff;
        margin-bottom: 1.06rem;
    }

    .navBar {
        height: 4.26rem; /* 160*/
    }

    .navBar ul {
        display: flex;
        flex-direction: row;
    }

    .navBar ul li {
        flex: 1;
        text-align: center;
        line-height: 4.26rem; /* 160*/
        font-size: 1.2rem;
        color: #666;
    }

    .navBar ul li.active {
        color: #333;
        font-weight: bold;
    }


    .orderTitle {
        display: flex;
        justify-content: space-between;
        padding-bottom: 1rem;
        border-bottom: 1px solid #d5d5d5;
    }

    .orderTitle label {
        color: #a2a2a2;
        margin-right: 0.75rem;
    }

    .status {
        color: #0088fb;
        font-size: 1.12rem;
        font-weight: bold;
    }

    .ready {
        color: #0088fb;
    }

    .pending {
        color: #fc7a3d;
    }

    .arrive {
        color: #00b147;
    }

    .cancelOrder {
        display: flex;
        justify-content: flex-end;
    }

    .aboutOrder h3 {
        font-size: 1.12rem;
        margin-top: 1.12rem;
        font-weight: bold;
    }

    .orderCommon {
        line-height: 3.04rem;
        color: #666;
    }

    .orderCommon label {
        font-size: 1.12rem;
        color: #999;
        margin-right: 1.33rem;
    }

    ::v-deep .van-nav-bar .van-icon {
        color: #666;
    }

    /* copy */
    .cancelOrder {
        border-top: 1px solid #f3f3f3;
    }

    .cancelBtn {
        color: #666;
        border-color: #d0d0d0;
        width: 8rem;
        height: 2.15rem;
        margin-top: 0.8rem;
    }

    .good {
        display: flex;
        padding: 1.2rem 0;

    }

    .good img {
        width: 8rem;
        height: 5.867rem;
        border-radius: 20px;
        margin-right: 20px;
    }

    .goodinfo {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .goodname {
        padding: 0.21rem 0;
        display: flex;
        justify-content: space-between;
    }

    .goodname > label > span:nth-child(1) {
        display: inline-block;
        padding: 3px 0.32rem;
        font-size: 0.693rem;
        line-height: 0.9rem;
        color: #fff;
        background-color: #fc7a3d;
        border-radius: 3px;
        margin-right: 0.48rem;
    }

    .goodname label span:nth-child(2) {
        font-size: 0.8rem;
        color: #333;

    }

    .shipping span {
        display: inline-block;
        font-size: 0.64rem;
        padding: 0.13rem 0.32rem;
        color: #999;
        line-height: 0.9rem;
        margin: 0.25rem 0;
        border: 1px solid #c7c6c6;
        border-radius: 0.9rem;
        box-sizing: border-box;
    }

    .price span {
        display: inline-block;
        color: #d81e06;
        font-weight: bold;
    }

    .price span:nth-child(1) {
        font-size: 0.69rem;
    }

    .price span:nth-child(2) {
        font-size: 1rem;
    }

    .curStores span {
        display: inline-block;
        line-height: 1.01rem;
        padding: 0.21rem 0.53rem;
        font-size: 0.64rem;
        color: #bdbdbd;
        border-radius: 1.01rem;
        background-color: #f5f5f5;
    }

    .text_16 {
        width: 3.36rem;
        height: 0.907rem;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 0.96rem;
        font-family: PingFang-SC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 0.96rem;
        margin-top: 0.214rem;
    }
</style>